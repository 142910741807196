

$(function () {
    filterActive();
    map();
    updateInput();
    submitForm();
    jqMaskForm();
    initShowProgram()
});

function initShowProgram() {

    const program =  $('#program');
    let titles = program.find('.program1__subtitle');
    let lists = program.find('.program1__list');
    let button = program.find('.program1__btn');


    $.each(titles, function(index, value){
        if (index > 0){
            $(this).hide();
        }else{
            $(this).show();
        }
    });
    $.each(lists, function(index, value){
        if (index > 0){
            $(this).hide();
        }else{
            $(this).show();
        }
    });

    button.on('click',function () {
        $(this).hide();
        $.each(titles, function(index, value){
            $(this).show();
        });
        $.each(lists, function(index, value){
            $(this).show();
        });
    });
}


function submitForm() {
    $('.selected1 .dropdown1__button-text').on('change',function (e) {
        $('#filterForm').submit();
    });
}

function jqMaskForm() {
   $('.request1-popup input[name = "phone"]').mask("8(999) 999-9999");
}

function updateInput() {
    let inputClaterProperties = $('input[name="claterProperties[]"]');
    let inputClaterNutrition = $('input[name="claterNutrition[]"]');
    let inputTypesNutrition = $('input[name="seasons[]"]');
    let inputFilterMainWhere = $('input[name="clusters[]"]');
    let inputFilterMainHow = $('input[name="activities[]"]');


    inputClaterNutrition.on('change',function (e) {
        let countClaterNutrition =  $('input[name="claterNutrition[]"]:checked').length;
        $('#clasterNutrition').html('Выбрано '+ countClaterNutrition);
    });
    inputClaterProperties.on('change',function (e) {
        let countClaterProperties =  $('input[name="claterProperties[]"]:checked').length;
        $('#claterProperties').html('Выбрано '+ countClaterProperties);
    });
    inputTypesNutrition.on('change',function (e) {
        let countClaterProperties =  $('input[name="seasons[]"]:checked').length;
        $('#typeNutrition').html('Выбрано '+ countClaterProperties);
    });
    inputFilterMainWhere.on('change',function (e) {
        let countType =  $('input[name="clusters[]"]:checked').length;
        $('#mainWhere').html('Выбрано '+ countType);
    });
    inputFilterMainHow.on('change',function (e) {
        let countClaterProperties =  $('input[name="activities[]"]:checked').length;
        $('#mainHow').html('Выбрано '+ countClaterProperties);
    });
}

function map() {
    var location = [];
    location.push($('#YMapsID').attr('data-location-latitude'));
    location.push($('#YMapsID').attr('data-location-longitude'));
    var title = 'Точка'
    if ($('#YMapsID').attr('data-title')){
        title = $('#YMapsID').attr('data-title')
    }
    var content = '<p>По данному месту информации пока нет </p>'
    if ($('#YMapsID').attr('data-content')){
        content = $('#YMapsID').attr('data-content')
    }

    if ($('#YMapsID').length != 0){
        ymaps.ready(function () {

            var myMap = new ymaps.Map("YMapsID", {
                center: location,
                zoom: 13
            });
            // Создаём макет содержимого.
            MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
                '<div style="color: #000; background: #fff; font-weight: bold; width: 100px; padding: 10px 5px;  border-radius: 5px;">'+ title+'</div>'
            ),
                myMap.geoObjects
                    .add(new ymaps.Placemark(location, {
                        balloonContent: content
                    }, {
                        iconLayout: 'default#imageWithContent',
                        iconImageHref: window.location.origin +'/images/iconMap.svg',
                        // Размеры метки.
                        iconImageSize: [48, 48],
                        iconImageOffset: [-48, -48],
                        // Смещение слоя с содержимым относительно слоя с картинкой.
                        iconContentOffset: [50, 0],
                        iconContentLayout: MyIconContentLayout
                    }))

        });
    }
}

function filterActive() {

    // фильтры объектов размещени
    $('.stop1 .sort1__list').find('.sort1__list-item').on('click',function (e) {
        $('.stop1 .sort1__list').find('.sort1__list-item').each(function() {

            $(this).removeClass('active')
        });
        $(this).addClass('active');

        filtersProperies();
    })

    $('.stop1 .content1__header-filter').find('.tag1').on('click',function (e) {

        if ($(this).attr('data-code') == 'all'){
            $('.stop1 .content1__header-filter').find('.tag1').each(function() {
                $(this).removeClass('active1')
            });
            $(this).addClass('active1');
        }else{
            if ($(this).hasClass('active1')){
                $(this).removeClass('active1')
            }else {
                $('.stop1 .content1__header-filter').find('.tag1').each(function() {
                    if ($(this).attr('data-code') == 'all') {
                        $(this).removeClass('active1')
                    }
                });
                $(this).addClass('active1');
            }
        }

        filtersProperies();
    })

    $('.stop1__form').find('.btn1').on('click',function (e) {
        e.preventDefault();
        filtersProperies();
    })

    // фильтры объектов питания
    $('.food1 .sort1__list').find('.sort1__list-item').on('click',function (e) {
        $('.food1 .sort1__list').find('.sort1__list-item').each(function() {

            $(this).removeClass('active')
        });
        $(this).addClass('active');

        filtersNutrition();
    })

    $('.food1 .content1__header-filter').find('.tag1').on('click',function (e) {
        if ($(this).attr('data-slug') == 'all'){
            $('.food1 .content1__header-filter').find('.tag1').each(function() {
                $(this).removeClass('active1')
            });
            $(this).addClass('active1');
        }else{
            if ($(this).hasClass('active1')){
                $(this).removeClass('active1')
            }else {
                $('.food1 .content1__header-filter').find('.tag1').each(function() {
                    if ($(this).attr('data-slug') == 'all') {
                        $(this).removeClass('active1')
                    }
                });
                $(this).addClass('active1');
            }
        }

        filtersNutrition();
    })


    $('.food1__form').find('.btn1').on('click',function (e) {
        e.preventDefault();
        filtersNutrition();
    })
}

function getDataFiltersProperies() {
    var dataSortPrice = filterSortPrice(); //получение дынных о сортировке(по цене)
    var dataFilterTags = filterTags(); //получение дынных о сортировке(по цене)
    var resultData = []; //конечные данные на выход

    resultData['filterSort'] = dataSortPrice;
    resultData['filterTags'] = dataFilterTags;

    function filterSortPrice() {
        var dataSort;
        $('.stop1 .sort1__list .sort1__list-item').each(function() {
            if ($(this).hasClass('active')){
                dataSort =  $(this).attr('data-stop-price');
            }
        });

        return dataSort;
    }

    function filterTags() {
        var dataTags = [];
        $('.stop1 .content1__header-filter .tag1').each(function() {
            if ($(this).hasClass('active1')){
                dataTags.push($(this).attr('data-code'));
            }
        });

        return dataTags;
    }


    return resultData;
}

function getDataFiltersNutrition() {
    var dataSortPrice = filterSortPrice(); //получение дынных о сортировке(по цене)
    var dataFilterTags = filterTags(); //получение дынных о сортировке(по цене)
    var resultData = []; //конечные данные на выход

    resultData['filterSort'] = dataSortPrice;
    resultData['filterTags'] = dataFilterTags;

    function filterSortPrice() {
        var dataSort;
        $('.food1 .sort1__list .sort1__list-item').each(function() {
            if ($(this).hasClass('active')){
                dataSort =  $(this).attr('data-stop-price');
            }
        });

        return dataSort;
    }

    function filterTags() {
        var dataTags = [];
        $('.food1 .content1__header-filter .tag1').each(function() {
            if ($(this).hasClass('active1')){
                dataTags.push($(this).attr('data-slug'));
            }
        });

        return dataTags;
    }


    return resultData;
}


function filtersProperies() {
    var resultData = getDataFiltersProperies();

    // url = '?' + $.param(resultData);
    $('.stop1__form input[name="filterSort"]').val(resultData['filterSort']);
    $('.stop1__form input[name="filterTags"]').val(resultData['filterTags']);

    $('.stop1__form').submit();
    // window.location.href = url;
}

function filtersNutrition() {
    var resultData = getDataFiltersNutrition();

    // url = '?' + $.param(resultData);
    $('.food1__form input[name="filterSort"]').val(resultData['filterSort']);
    $('.food1__form input[name="filterTags"]').val(resultData['filterTags']);

    $('.food1__form').submit();
    // window.location.href = url;
}


function initPagination() {
          $('.content1__btn.more__content.pagination-properties').on('click', function () {
            let currentButton = $(this);
            let currentListItem = $(this).closest('.price1__list-item');
            let propertyId = currentListItem.attr('data-propertyid');
            let roomId = currentListItem.attr('data-roomid');
            let url = $(this).attr('data-url');
            let currentPage = $(this).attr('data-currentpage');
			if (currentPage == 1) {
                currentPage = 2;
            }
            let lastPage = $(this).attr('data-lastpage');
            if(currentPage == lastPage) {
              $(this).css('display', 'none');
            }

			let aHref =  $('.content1__list-item .card1 a').attr('href').split('?');
			let resultParams = (typeof aHref[1] !== undefined) ? ('&' + aHref[1]) : '';

            $(this).attr('data-currentpage', (parseInt(currentPage) + 1));
            $(this).attr('data-url', '/where-to-stay?page=' + (parseInt(currentPage) + 1) + '&type=properties' + resultParams);
            $.ajax({
              url: window.location.origin + url,
              data: {propertyId: propertyId, roomId: roomId},
              type: 'GET',
              success: function (result) {
                $('.content1__list.pagination-properties').append(result);
                currentButton.off('click');
                initPagination();
                // initSliders(".card1 .swiper");

				    let sliderSelector = ".card1 .swiper";
					const sliders = document.querySelectorAll(sliderSelector);

					if (sliders.length > 0) {
					  sliders.forEach(slider => {
						const prev = slider.querySelector(".swiper1__buttons-prev");
						const next = slider.querySelector(".swiper1__buttons-next");
						const pagination = slider.querySelector(".swiper1__pagination");

						const swiper = new Swiper(slider, {
						  spaceBetween: 10,
						  navigation: {
							nextEl: next,
							prevEl: prev,
						  },
						  pagination: {
							el: pagination,
							type: "bullets"
						  },
						  nested: true
						});
					  });
					}
              }
            });
          })


        $('.content1__btn.more__content.pagination-nutrition').on('click', function () {
            let currentButton = $(this);
            // let currentListItem = $(this).closest('.price1__list-item');
            // let propertyId = currentListItem.attr('data-nutritionid');
            // let roomId = currentListItem.attr('data-roomid');
            let url = $(this).attr('data-url');
            let currentPage = $(this).attr('data-currentPage');
			if (currentPage == 1) {
                currentPage = 2;
            }
            let lastPage = $(this).attr('data-lastPage');
            if(currentPage == lastPage) {
                $(this).css('display', 'none');
            }
            $(this).attr('data-currentPage', (parseInt(currentPage) + 1));
            // console.log('/where-to-stay?pageNutrition=' + (parseInt(currentPage) + 1));
            $(this).attr('data-url', '/where-to-stay?page=' + (parseInt(currentPage) + 1) + '&type=nutrition');
            $.ajax({
                url: window.location.origin + url,
                // data: {propertyId: propertyId, roomId: roomId},
                type: 'GET',
                success: function (result) {
                    $('.content1__list.pagination-nutrition').append(result);
                    currentButton.off('click');
                    initPagination();
                }
            });
        })
}

function initPaginationNew(buttonName1, classList, buttonName2 = null, dataAttribute = null) {
          $(buttonName1).on('click', function () {
            let currentButton = $(this);
            let url = $(this).attr('data-url');
            let currentPage = parseInt($(this).attr('data-currentpage'));
            let lastPage = parseInt($(this).attr('data-lastpage'));
            let typeRequest = $(this).attr('data-request');

            if (currentPage === 1) {
              currentPage = 2;
            }

            if (currentPage === lastPage) {
              $(this).css('display', 'none');
            }

            $(this).attr('data-currentpage', currentPage + 1);
            $(this).attr('data-url', '/what-to-do?page=' + (currentPage + 1));

            let parameters = [];
            if(buttonName2 != null) {
              $(buttonName2).each(function () {
                if (parseInt($(this).attr('data-isactive')) === 1) {
                  parameters.push(parseInt($(this).attr(dataAttribute)));
                }
              })
            }

            $.ajax({
              url: window.location.origin + url,
              data: {typeRequest: typeRequest, typeClick: 'paginate', parameters: parameters},
              type: 'GET',
              success: function success(result) {
                $(classList).append(result);
                currentButton.off('click');
                initPaginationNew(buttonName1, classList, buttonName2, dataAttribute);
              }
            });
          });
        }
        function initPaginationLocalities(elementPaginate, classList, filterElement = null, itemAttribute = null) {
            $(elementPaginate).on('click', function () {
                let currentElementPaginate = $(this);
                let url = $(this).attr('data-url');
                let currentPage = parseInt($(this).attr('data-currentpage'));
                let lastPage = parseInt($(this).attr('data-lastpage'));
                let typeRequest = $(this).attr('data-request');

                if (currentPage === 1) {
                    currentPage = 2;
                }

                if (currentPage === lastPage) {
                    $(this).css('display', 'none');
                }

                $(this).attr('data-currentpage', currentPage + 1);
                $(this).attr('data-url', '/localities?page=' + (currentPage + 1));

                let parameters = [];
                console.log(filterElement)
                if ($(filterElement + ' option:selected')) {
                    parameters.push($(filterElement + ' option:selected').val());
                }


                $.ajax({
                    url: window.location.origin + url,
                    data: {typeRequest: typeRequest, typeClick: 'paginate', parameters: parameters},
                    type: 'GET',
                    success: function success(result) {
                        $(classList).append(result);
                        currentElementPaginate.off('click');
                        initPaginationLocalities(elementPaginate, classList, filterElement, itemAttribute);
                    }
                });
            });
        }
        function initFilterPartial(buttonName1, dataAttribute, pathRequest, selector1, selector2, selector3, buttonName2) {
          $(buttonName1).each(function () {
            let currentButton = $(this);
            currentButton.on('click', function () {
              if (parseInt($(this).attr('data-isactive')) === 1) {
                $(this).attr('data-isactive', 0);
                $(this).removeAttr('style');
              } else if (parseInt($(this).attr('data-isactive')) === 0) {
                $(this).attr('data-isactive', 1);
                $(this).css('background-color', '#DC5833');
                $(this).css('opacity', 0.75);
              }
              let parameters = [];
              $(buttonName1).each(function () {
                if (parseInt($(this).attr('data-isactive')) === 1) {
                  parameters.push(parseInt($(this).attr(dataAttribute)));
                }
              })
              let typeRequest = $(this).attr('data-request');
              $.ajax({
                url: window.location.origin + '/what-to-do',
                data: {parameters: parameters, typeRequest: typeRequest, typeClick: 'filter'},
                type: 'GET',
                success: function success(result) {
                  $(selector1).remove();
                  $(selector2).remove();
                  $(selector3).append(result);
                  initPaginationNew(buttonName2,selector1,buttonName1, dataAttribute);
                }
              });
            })
          })
        }
function initFilterPartialocalities(filterElement, classList, selector2, selector3, elementPaginate,filterPaginate) {
    $(filterElement).each(function () {
        let currentFilterElement = $(this);
        currentFilterElement.on('click', function () {
            let parameters = [];
            if ($(this)) {
                parameters.push($(this).attr('data-cluster'));
            }
            let typeRequest = $(this).attr('data-request');
            $.ajax({
                url: window.location.origin + '/localities',
                data: {parameters: parameters, typeRequest: typeRequest, typeClick: 'filter'},
                type: 'GET',
                success: function success(result) {
                    $(selector2).children().remove();
                    $(selector3).append(result);
                    initPaginationLocalities(elementPaginate, classList, filterPaginate);
                }
            });
        })
    })
}

function initSortPartial(removeList, removeButton, selectorAppend, classButton1, classButton2) {
          $('.sort1__list-item.' + classButton1).each(function () {
            let currentButton = $(this);
            currentButton.on('click', function () {
              let typeRequest = $(this).attr('data-typerequest');

              $.ajax({
                url: window.location.origin + '/what-to-do',
                data: {sort: 'ASC', typeRequest: typeRequest, typeClick: 'filter'},
                type: 'GET',
                success: function success(result) {
                  $(removeList).remove();
                  $(removeButton).remove();
                  $(selectorAppend).append(result);
                  initPaginationNew(removeButton, removeList);
                }
              });
            })
          })
          $('.sort1__list-item.' + classButton2).each(function () {
            let currentButton = $(this);
            currentButton.on('click', function () {
              let typeRequest = $(this).attr('data-typerequest');

              $.ajax({
                url: window.location.origin + '/what-to-do',
                data: {sort: 'DESC', typeRequest: typeRequest, typeClick: 'filter'},
                type: 'GET',
                success: function success(result) {
                  $(removeList).remove();
                  $(removeButton).remove();
                  $(selectorAppend).append(result);
                  initPaginationNew(removeButton, removeList);
                }
              });
            })
          })
        }

$(document).ready(function () {
			initPagination();

			$('.btn1.reservation').on('click', function () {
                let isAuth = $(this).attr('data-isauth');
                if (isAuth == false) {
                    let modal = $('div[data-modal="is-auth"]');
                    modal.addClass('js-active1');
                }
            })

            $('.confirm1-popup__btn.is-auth').on('click', function () {
                let modal = $('div[data-modal="is-auth"]');
                $('.price1__descr.popup1').removeClass('js-active1');
                modal.removeClass('js-active1');
                $('.header__profile.link').click();
            })

			$('.history1__list-item .order1__content-btn').each(function () {
                let currentButton = $(this);
                currentButton.on('click', function () {
                    if ($(this).attr('data-number') !== undefined) {
                        let number = $(this).attr('data-number');
                        let applicationId = $(this).attr('data-applicationid');
                        let modal = $('div[data-modal="confirm-cancellation"]');
                        modal.addClass('js-active1');
                        $.ajax({
                            url: window.location.origin + '/where-to-stay/calculate-cancellation-penalty',
                            data: {number: number},
                            type: 'GET',
                            success: function (result) {
                                console.log(result);
                                modal.find('.text-penalty1').css('display', 'none');
                                modal.find('.text-penalty2').text('Сумма штрафа будет составлять ' + result['penaltyAmount'] + 'р.');
                                modal.find('.text-penalty2').css('display', 'block');
                                modal.find('.text-penalty3').css('display', 'block');

                                modal.attr('data-number', number);
                                modal.attr('data-penalty-amount', result['penaltyAmount']);
                                modal.attr('data-applicationid', applicationId);
                            }
                        });
                    }
                })
            })

            $('.confirm1-popup__btn.cancellation-yes').on('click', function () {
                let modal = $('div[data-modal="confirm-cancellation"]');
                let number = modal.attr('data-number');
                let penaltyAmount = modal.attr('data-penalty-amount');
                let applicationId = modal.attr('data-applicationid');
                let buttonNumber = $('button[data-applicationid="' + applicationId + '"]');
                $.ajax({
                    url: window.location.origin + '/where-to-stay/cancel-booking',
                    data: {number: number, penaltyAmount: penaltyAmount, applicationId: applicationId},
                    type: 'GET',
                    success: function (result) {
                        console.log(result);
                        buttonNumber.closest('.order1').removeClass('active1');
						buttonNumber.closest('.order1').find('.order1__content-btn').text('Бронирование отменено');
                        modal.removeClass('js-active1');
                        modal.find('.text-penalty1').css('display', 'block');
                        modal.find('.text-penalty2').css('display', 'none');
                        modal.find('.text-penalty3').css('display', 'none');
                    }
                });
            })

            $('.confirm1-popup__btn.cancellation-no').on('click', function () {
                let modal = $('div[data-modal="confirm-cancellation"]');
                modal.removeClass('js-active1');
                modal.find('.text-penalty1').css('display', 'block');
                modal.find('.text-penalty2').css('display', 'none');
                modal.find('.text-penalty3').css('display', 'none');
            })

          $('.btn1.get-details').each(function () {
            let getDetailsButton = $(this);
            getDetailsButton.on('click', function () {

              let currentListItem = $(this).closest('.price1__list-item');
              let propertyId = currentListItem.attr('data-propertyid');
              let roomId = currentListItem.attr('data-roomid');
              let row = currentListItem.attr('data-row');
			  let manualObject = currentListItem.attr('data-manualobject'); // new
			  let checksum = currentListItem.attr('data-checksum'); // new

			  let dateResidencefirst = $('input[name="dateResidencefirst"]').val(); // new
			  let dateResidenceLast = $('input[name="dateResidenceLast"]').val(); // new
			  let adultCount = $('input[name="adults"]').val(); // new

              let modal = $('.price1__descr.popup1');

              let listItems = $('div[data-row="' + row +'"]');
              modal.detach().insertAfter(listItems[listItems.length - 1]);

              $.ajax({
                url: window.location.origin + '/where-to-stay/get-details',
                data: {propertyId: propertyId, roomId: roomId, manualObject: manualObject, checksum: checksum, dateResidencefirst: dateResidencefirst, dateResidenceLast: dateResidenceLast, adultCount: adultCount,},
                type: 'GET',
                success: function (result) {
                  console.log(result);

                  let containerImages = $('.price1__descr-img');
                  containerImages.find('img').remove();
                  containerImages.find('.row1').remove();

				  if(typeof result['images'] !== "undefined") {
					  let images = result['images'];
					  if (images.length > 0 || images.length === 1) {
						containerImages.append('<img src="' + images[0]['url'] + '" alt="" class="big1">');
					  }
					  if (images.length > 2) {
						containerImages.append('<div class="row1"><img style="width: 120px" src="' + images[1]['url'] + '" alt=""><img  style="width: 120px" src="' + images[2]['url'] + '" alt=""></div>');
					  } else if (images.length > 1) {
						containerImages.append('<div class="row1"><img style="width: 120px" src="' + images[1]['url'] + '" alt=""></div>');
					  }
					  if (images.length > 3) {
						containerImages.find('.row1').append('<div class="more1"><div class="text1">' + images.length + ' фото</div><img style="width: 120px" src="' + images[3]['url'] + '" alt=""></div>');

						$('.photo1.popup1 .swiper .swiper-wrapper .swiper-slide').remove();
						let modalPhoto = $('.photo1.popup1 .swiper .swiper-wrapper');
						for (let i = 3; i < images.length; i++) {
						  modalPhoto.append('<div class="swiper-slide"><img src="' + images[i]['url'] + '" alt=""></div>');
						}

						const slider3 = new Swiper(".photo1 .swiper", {
						  navigation: {
							nextEl: '.photo1 .swiper1__button-next',
							prevEl: '.photo1 .swiper1__button-prev',
						  },
						  slidesPerView: 1,
						  spaceBetween: 20,
						  pagination: {
							el: '.photo1 .swiper-pagination',
							type: "bullets"
						  },
						});
					  }
				  }

				/*
                  $('.price1__descr-content ul').remove();
                  $('.price1__descr-content p').remove();
                  $('.price1__descr-content').prepend('<p style="margin-bottom: 20px"></p>');
                  $('.price1__descr-content p').html(result['description']);

				  $('.price1__descr-content').prepend('<div class="title1">Информация о питании:</div><p id="meal_plan" style="margin-bottom: 20px"></p></div>');
				  $('.price1__descr-content p#meal_plan').html(((result['mealPlan'] != null) ? result['mealPlan']['name'] : 'Описание отсутствует'));
				  $('.price1__descr-content').prepend('<div class="title1">Штраф за отмену бронирования:</div><p id="cancellation_policy" style="margin-bottom: 20px"></p></div>');
				  $('.price1__descr-content p#cancellation_policy').html(((result['cancellationPolicy'] != null) ? (result['cancellationPolicy']['penaltyAmount'] + ' р.') : 'Описание отсутствует'));
				  $('.price1__descr-content').prepend('<div class="title1">Расселение гостей:</div><p id="accommodations" style="margin-bottom: 20px"></p></div>');
				  $('.price1__descr-content p#accommodations').html(((result['fullPlacementsName'] != '') ? result['fullPlacementsName'] : 'Описание отсутствует'));
				*/

				  $('.price1__descr-content p#description_room').remove();
				  $('.price1__descr-content p#meal_plan').remove();
				  $('.price1__descr-content p#cancellation_policy').remove();
				  $('.price1__descr-content p#accommodations').remove();
				  $('.price1__descr-content .title1').remove();
                  
				  $('.price1__descr-content').prepend('<p id="description_room" style="margin-bottom: 20px"></p>');
                    if (manualObject != 1) {
                        $('.price1__descr-content').prepend('<div class="title1">Информация о питании:</div><p id="meal_plan" style="margin-bottom: 20px"></p></div>');
                        $('.price1__descr-content').prepend('<div class="title1">Штраф за отмену бронирования:</div><p id="cancellation_policy" style="margin-bottom: 20px"></p></div>');
                        $('.price1__descr-content').prepend('<div class="title1">Расселение гостей:</div><p id="accommodations" style="margin-bottom: 20px"></p></div>');
                    }
				  $('.price1__descr-content').prepend('<p style="margin-bottom: 20px"></p>');
				  $('.price1__descr-content p#description_room').html(result['description']);
				  $('.price1__descr-content p#meal_plan').html(((result['mealPlan'] != null) ? result['mealPlan']['name'] : 'Описание отсутствует'));

				if (manualObject != 1) {
				  let resultText = '';
				  if(result['cancellationPolicy']['freeCancellationPossible'] === false) {
					resultText = ((result['cancellationPolicy'] != null) ? (result['cancellationPolicy']['penaltyAmount'] + ' р.') : 'Описание отсутствует');
				  } else {
					let freeCancellationDeadlineLocal = result['cancellationPolicy']['freeCancellationDeadlineLocal'];
					let freeCancellationDeadlineUtc = result['cancellationPolicy']['freeCancellationDeadlineUtc'];
					let date1 = new Date(freeCancellationDeadlineLocal);
					let date2 = new Date(freeCancellationDeadlineUtc.slice(0, -1));
					let timezoneObject = (date1 - date2) / (1000 * 3600);
					let resultDate = freeCancellationDeadlineLocal.split('T').join(' ') + ' (GMT' + ((timezoneObject >= 0) ? ('+' + timezoneObject + ')') : (timezoneObject + ')'));
					resultText = ((result['cancellationPolicy'] != null) ? ('До ' + resultDate + ' штраф отсутствует, после будет составлять ' + result['cancellationPolicy']['penaltyAmount'] + ' р.') : 'Описание отсутствует');
				  }
				  $('.price1__descr-content p#cancellation_policy').html(resultText);
				}

				  $('.price1__descr-content p#accommodations').html(((result['fullPlacementsName'] != '') ? result['fullPlacementsName'] : 'Описание отсутствует'));

                  let parametersForm = $('.form1');
                  let arrivalDate = parametersForm.find('input[name="dateResidencefirst"]').val();
                  let departureDate = parametersForm.find('input[name="dateResidenceLast"]').val();
                  let adultCount = parametersForm.find('input[name="adults"]').val();
                  let kidCount = parametersForm.find('input[name="childAges"]').val();

                  let formReservation = $('form[name="reservation"]');
                  formReservation.find('input[name=roomId]').val(roomId);
                  formReservation.find('input[name=arrivalDate]').val(arrivalDate);
                  formReservation.find('input[name=departureDate]').val(departureDate);
                  formReservation.find('input[name=adultCount]').val(adultCount);
                  formReservation.find('input[name=kidCount]').val(kidCount);
                  formReservation.find('input[name=propertyId]').val(propertyId);
				  formReservation.find('input[name=checksum]').val(checksum);

                  if (manualObject != 1) {
                    $('.detail_video_audio .text1').text('').html('<img src="/images/room-icons/tv.png" alt="" class="img1">' + checkAmenities(1, result['amenities']));
                    $('.detail_bathroom .text1').text('').html('<img src="/images/room-icons/towel.png" alt="" class="img1">' + checkAmenities(2, result['amenities']));
                    $('.detail_internet_phone .text1').text('').html('<img src="/images/room-icons/phone.png" alt="" class="img1">' + checkAmenities(3, result['amenities']));
                    $('.detail_bed .text1').text('').html('<img src="/images/room-icons/bed.png" alt="" class="img1">' + checkAmenities(4, result['amenities']));
                    $('.detail_electronic .text1').text('').html('<img src="/images/room-icons/lamp.png" alt="" class="img1">' + checkAmenities(5, result['amenities']));
                    $('.detail_furniture .text1').text('').html('<img src="/images/room-icons/mirror.png" alt="" class="img1">' + checkAmenities(6, result['amenities']));
                  } else if (manualObject == 1) {
                    $('.detail_video_audio .text1').text('').html('<img src="/images/room-icons/tv.png" alt="" class="img1">' + result['amenities']['video_audio']);
                    $('.detail_bathroom .text1').text('').html('<img src="/images/room-icons/towel.png" alt="" class="img1">' + result['amenities']['bathrooms']);
                    $('.detail_internet_phone .text1').text('').html('<img src="/images/room-icons/phone.png" alt="" class="img1">' + result['amenities']['internet_phone']);
                    $('.detail_bed .text1').text('').html('<img src="/images/room-icons/bed.png" alt="" class="img1">' + result['amenities']['beds']);
                    $('.detail_electronic .text1').text('').html('<img src="/images/room-icons/lamp.png" alt="" class="img1">' + result['amenities']['electronics']);
                    $('.detail_furniture .text1').text('').html('<img src="/images/room-icons/mirror.png" alt="" class="img1">' + result['amenities']['furniture']);
                  }

                  modal.find('.wait-loader').css('display', 'none');
                  modal.find('.two-column1').css('display', 'grid');
                }
              });
            })
          })

          $('.price1__descr-close').on('click', function () {
            let modal = $('.price1__descr.popup1');
            modal.find('.wait-loader').css('display', 'block');
            modal.find('.two-column1').css('display', 'none');
          })

          $('.request1__close.popup1__close').on('click', function () {
            let modal = $('.price1__descr.popup1');
            modal.find('.wait-loader').css('display', 'block');
            modal.find('.two-column1').css('display', 'none');
          })

          function checkAmenities(typeAmenity, aAmenities) {
            let aVideoAudio = ['flat_screen_TV', 'tv', 'two_tv', 'satellite_television', 'cable_television',
              'interactive_web_tv', 'dvd_player', 'movies', 'home_cinema', 'karaoke', 'media_center', 'magnitofon',
              'chinese_channel', 'digital_tv', 'hbo'];

            let aBathrooms = ['jacuzzi_bathroom', 'bathroom', 'shower_cabin', 'bathtub', 'bath_or_shower', 'aeromassage_bath',
              'hydromassage_bath', 'hydromassage_shower', 'bathroom_with_wc', 'additional_bathroom_with_wc',
              'additional_bathroom', 'bidet', 'bathroom_amenities', 'bathrobe', 'bath_towels', 'beach_towels', 'hygienic_aids',
              'beauty_aids', 'sauna', 'three_bathrooms', 'separate_closet', 'shared_bathroom',
              'shared_bathroom_and_toilet_on_floor', 'shower', ];

            let aInternetPhones = ['wifi_internet', 'phone', 'internet', 'telephone', 'ip_telephone', 'two_line_phone',
              'fax', '121', 'in_room_telephone', ];

            let aBeds = ['three_single_beds', 'king_bed', 'two_single_beds', 'orthopedic_mattress', 'hypoallergenic_linen',
              'double_bed', 'queen_bed', 'single_bed', '126', 'two_full_beds', ];

            let aElectronics = ['multi', 'refrigerator', 'hairdryer', 'microwave', 'electronic_locks',
              'split_air_conditioning_system', 'air_conditioning', 'air_conditioning_individually_controlled_in_room',
              'hairdryer', 'fan', 'scales', 'alarm_clock', 'stove', 'coffee_machine', 'iron', 'radio', 'heater', 'pc',
              'water_heater', 'warm_floor', 'kettle', 'toaster', 'mini_fridge', 'central_air_conditioning', 'smart_house', ];

            let aFurniture = ['work_table', 'queen_bed', 'mirror', 'chairs', 'desk_lamps', 'lamp', 'ironing_board', 'sofa_bed',
              'armchairs', 'nightstands', 'closet_for_clothes', 'wardrobe', 'hangers', 'journal_table', 'desk',
              'conference_table', 'dinner_table', 'luggage_stand', 'bar', 'mirror', 'commode', 'padded_stools', 'carpet',
              'exclusive_furniture', 'safe', 'dishwasher', 'trouser_press', 'washing_machine', 'soft_furniture', 'table',
              'washer_dryer', 'veshalka', 'toilet_table', 'studio_couch', 'folding_sofa', 'folding_armchair', 'kitchenette',
              'sliding_door_wardrobe', 'armchair', 'clothes_airer', 'coffee_end_tables', 'cosmetic_mirror', 'dresser',
              'garbage_disposal', 'nightstand', 'outdoor_grill', 'desk_lamps', ];

            if (typeAmenity === 1) {
              return getTextAmenities(aAmenities, aVideoAudio);
            } else if (typeAmenity === 2) {
              return getTextAmenities(aAmenities, aBathrooms);
            } else if (typeAmenity === 3) {
              return getTextAmenities(aAmenities, aInternetPhones);
            } else if (typeAmenity === 4) {
              return getTextAmenities(aAmenities, aBeds);
            } else if (typeAmenity === 5) {
              return getTextAmenities(aAmenities, aElectronics);
            } else if (typeAmenity === 6) {
              return getTextAmenities(aAmenities, aFurniture);
            }
          }

          function getTextAmenities(aAmenities, aValues) {
            let resultText = '';

            aAmenities.forEach(function(item) {
              if (aValues.includes(item['code'])) {
                resultText += (resultText === '') ? item['name'] : (', ' + item['name']);
              }
            });

            if (resultText === '') {
              resultText = 'Описание отсутствует.';
            }

            return resultText;
          }


  // new
  initPaginationLocalities('.content1__btn.localities','#localities .where-to-go1__list','#filterCluster');

  initFilterPartialocalities('.selected1 .dropdown1__list-item',
      '#localities .where-to-go1__list', '#localities', '#localities','.content1__btn.localities','#filterCluster')

  initPaginationNew('.content1__btn.pagination-trail','#trails .trails1__list',
      '.tag1.difficulty', 'data-difficulty');
  initPaginationNew('.content1__btn.pagination-museum','#museums .objects1__list');
  initPaginationNew('.content1__btn.pagination-attraction','#attractions .objects1__list',
      '.tag1.type-attraction', 'data-typeattraction');
  initPaginationNew('.content1__btn.pagination-guide','#guides .tours1__list');
  initPaginationNew('.content1__btn.pagination-tour','#tours .tours1__list');
  initPaginationNew('.content1__btn.pagination-object','#objects .objects1__list');

  initFilterPartial('.tag1.difficulty', 'data-difficulty', 'partial-trail',
      '#trails .trails1__list', '#trails .pagination-trail', '#trails',
      '.content1__btn.pagination-trail');
  initFilterPartial('.tag1.type-attraction', 'data-typeattraction','partial-attraction',
      '#attractions .objects1__list','#attractions .pagination-attraction', '#attractions',
      '.content1__btn.pagination-attraction');

  initSortPartial("#museums .objects1__list", ".content1__btn.pagination-museum", "#museums", "museum_asc", "museum_desc");
  initSortPartial("#objects .objects1__list", ".content1__btn.pagination-object", "#objects", "object_asc", "object_desc");
  initSortPartial("#tours .tours1__list", ".content1__btn.pagination-tour", "#tours", "tour_asc", "tour_desc");

  $('form[name="filter-wtd"] .checkbox1').on('click', function () {
    $('form[name="filter-wtd"]').submit();
  })

  $('.btn1.reset_filter').on('click', function () {
    window.location.href = window.location.origin + '/what-to-do';
  })

  $('.dropdown1__list-item.territory_filter').on('click', function () {
    $('form[name="events_filter"]').submit();
  })

  $('.dropdown1__list-item.season_filter').on('click', function () {
    $('form[name="events_filter"]').submit();
  })

  $('form[name="events_filter"] .checkbox1').on('click', function () {
    $('form[name="events_filter"]').submit();
  })

  initPaginationEvent();
  initPaginationSearch();

        $('form[name="filter-search"] .result1__filter-item').on('click', function () {
          $('form[name="filter-search"]').submit();
        })
})

function initPaginationEvent() {
  $('.content1__btn.events1__btn').on('click', function () {
    let currentButton = $(this);
    let url = $(this).attr('data-url');
    let currentPage = parseInt($(this).attr('data-currentpage'));
    let lastPage = parseInt($(this).attr('data-lastpage'));

    if (currentPage === 1) {
      currentPage = 2;
    }

    if (currentPage === lastPage) {
      $(this).css('display', 'none');
    }

    let aHref =  window.location.href;
    if (aHref !== '') {
      aHref = aHref.split('?')
    }
    let resultParams = (typeof aHref[1] !== undefined) ? ('&' + aHref[1]) : '';

    $(this).attr('data-currentpage', currentPage + 1);
    $(this).attr('data-url', '/events?page=' + (currentPage + 1) + resultParams);
    $.ajax({
      url: window.location.origin + url + resultParams,
      type: 'GET',
      success: function success(result) {
        $('.events1__list').append(result);
        currentButton.off('click');
        initPaginationEvent();
      }
    });
  });
}


        function initPaginationSearch() {
          $('.content1__btn.pagination-search').on('click', function () {
            let currentButton = $(this);
            let url = $(this).attr('data-url');
            let currentPage = parseInt($(this).attr('data-currentpage'));
            let lastPage = parseInt($(this).attr('data-lastpage'));
            let totalCount = parseInt($(this).attr('data-total'));
            let perPage = parseInt($(this).attr('data-perpage'));

            if (currentPage === 1) {
              currentPage = 2;
            }

            if (currentPage === lastPage) {
              $(this).css('display', 'none');
            }

            let aHref =  window.location.href;
            if (aHref !== '') {
              aHref = aHref.split('?')
            }
            let resultParams = (typeof aHref[1] !== undefined) ? ('&' + aHref[1]) : '';

            $(this).attr('data-currentpage', currentPage + 1);
            $(this).attr('data-url', '/search?page=' + (currentPage + 1) + resultParams);
            $.ajax({
              url: window.location.origin + url + resultParams,
              type: 'GET',
              success: function success(result) {
                $('.result1__list-footer').before(result);
                $('.show1').text('показано ' + ((lastPage === currentPage) ? totalCount : (currentPage * perPage)) + ' из ' + totalCount);
                currentButton.off('click');
                initPaginationSearch();
              }
            });
          });
        }

function toggleClass(triggerSelector, blockSelector, className) {
    const blocks = document.querySelectorAll(blockSelector);

    if (blocks.length > 0) {
        blocks.forEach(block => {
            block.querySelector(triggerSelector).addEventListener("click", () => {
                block.classList.toggle(className);
            });
        });
    }
}
